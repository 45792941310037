.text {
  &__bold {
    font-weight: 600;
  }
  &__thin {
    font-weight: 200;
  }
  &__light {
    font-weight: 100;
  }
  &__size120 {
    font-size: 30px;
    line-height: 25px;
    @media screen and (min-width: 400px) {
      font-size: 40px;
      line-height: 30px;
    }
    @media screen and (min-width: 560px) {
      font-size: 60px;
      line-height: 50px;
    }
    @media screen and (min-width: 768px) {
      font-size: 80px;
      line-height: 70px;
    }
    @media screen and (min-width: 992px) {
      font-size: 90px;
      line-height: 80px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 120px;
      line-height: 110px;
    }
  }
  &__size60 {
    font-size: 30px;
    line-height: 38px;
    @media screen and (min-width: 480px) {
      font-size: 40px;
      line-height: 48px;
    }
    @media screen and (min-width: 768px) {
      font-size: 60px;
      line-height: 68px;
    }
  }
  &__size40 {
    font-size: 40px;
    line-height: 50px;
  }
  &__size26{
    font-size: 26px;
    line-height: 34px;
  }
  &__size20 {
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 24px;
  }
  &__size18 {
    font-size: 18px;
    line-height: 26px;
  }
  &__size16 {
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 2px;
  }
  &__size14 {
    font-size: 14px;
    line-height: 26px;
  }
}