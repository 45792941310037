body._has-cookie-modal {
  &:before {
    content: '';
    display: block;
    background-color: rgba(87, 87, 87, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
  }

  .modals-overlay {
    display: none;
  }
}

.popup-cookies-details {
  z-index: 2000000002 !important;
  color: #333;

  .action-close {
    display: none;
  }

  #cookies-details-popup > .row {
    margin: 0;
  }

  .modal-header {
    padding-inline: 16px;
  }

  .modal-inner-wrap {
    width: auto;

    @media screen and (min-width: $screen__m) {
      width: 720px;
    }
  }

  a {
    color: $consent__color-main;
    text-decoration: underline;
  }

  .modal-content {
    padding-inline: 16px;

    .cookie__text {
      max-width: unset;
    }
  }

  .cookie-type {
    margin-bottom: 16px;
  }

  .cookies-list {
    text-align: left;

    .cookie-container {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      &.disabled label {
        cursor: default;
      }

      .cookie-type-info {
        max-width: calc(100% - #{$consent__switch-input__width});
        padding-right: 10px;
        box-sizing: border-box;
      }

      .cookie-type-label {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .cookies {
      max-height: 150px;
      overflow-y: auto;
      padding-inline: 5px;
      background-color: $consent__color-additional-background;
      border-radius: 10px;

      .list {
        padding: 10px;
      }

      .row {
        display: grid;
        grid-template-columns: calc(50% - #{$consent__cookie-list__column-gap / 2}) calc(50% - #{$consent__cookie-list__column-gap / 2});
        margin-bottom: 8px;
        column-gap: $consent__cookie-list__column-gap;

        &::before, &::after {
          display: none;
        }
      }

      .col {
        word-break: break-all;
      }

      .cookies-list-header {
        border-bottom: 1px solid #000000;
        font-weight: 700;
      }
    }

    .show-cookies-toggle-container a {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &.modal-slide._inner-scroll .modal-footer {
    margin-top: 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;

    button {
      margin: 0 auto;
      width: 100%;
      padding: 14px 20px !important;
      font-size: 1.4rem !important;
      text-transform: uppercase;
      background: $consent__color-main;
      letter-spacing: 1px;
      border: none;
      margin-bottom: 15px;
      line-height: 1.6rem !important;
    }
  }
}

.message.global.consent {
  z-index: 2000000001;
  background-color: #ffffff;
  margin: 0 !important;
  padding: 50px 0;
  color: #333;

  a {
    color: $consent__color-main;
    text-decoration: underline;
  }

  .content {
    display: block;
    max-width: 1320px;
    max-height: 70vh;
    overflow: hidden;
    margin: 0 auto;
    padding: 0 20px;
    font-size: 1.4rem;

    >.row {
      margin: 0;
      display: flex;
      flex-direction: column;

      > div {
        padding-right: 20px;
        text-align: justify;
      }
    }
  }

  #show-cookie-details {
    color: $consent__color-main;
    text-decoration: none;
    cursor: pointer;
  }

  .consent-btn {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    &.btn-cookie-action {
      width: 100%;
    }
  }

  .buttons-col {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .cookie-preferences {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .cookie-container {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      label {
        margin-right: 10px;
      }

      &.disabled label {
        cursor: default;
      }
    }
  }

    button {
      background: $consent__color-main;
      border: none;
      letter-spacing: 1px;
      margin-bottom: 16px;
      width: 100%;
      box-sizing: border-box;
      font-size: 1.4rem !important;
      padding: 14px 20px !important;
      width: max-content;
      line-height: 1.6rem !important;
    }
}

.switch-input {
  position: relative;
  display: inline-block;
  width: $consent__switch-input__width;
  height: 34px;

  &.disabled {
    cursor: default;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked+.slider {
      background-color: $consent__color-additional;

      &::before {
        -webkit-transform: translateX($consent__toggle-slider__size);
        -ms-transform: translateX($consent__toggle-slider__size);
        transform: translateX($consent__toggle-slider__size);
      }
    }

    &:focus+.slider {
      box-shadow: 0 0 1px $consent__color-additional;
    }

    &:disabled+.slider {
      background-color: $consent__color-additional-disabled;
      cursor: default;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: $consent__toggle-slider__speed;
    transition: $consent__toggle-slider__speed;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      height: $consent__toggle-slider__size;
      width: $consent__toggle-slider__size;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: $consent__toggle-slider__speed;
      transition: $consent__toggle-slider__speed;
      border-radius: 50%;
    }
  }
}

.action.primary.consent-btn {
  background: $consent__color-main;
  border: none;
}

@media screen and (min-width: $screen__m) {
  .message.global.consent {
    .content>.row {
        flex-direction: row;
      }
    
    .buttons-col {
      margin-top: 0;
    }

    .consent-btn.btn-cookie-action {
      width: max-content;
    }
  }
  
  .popup-cookies-details {
    &.modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 80vh;
    }
  
    .modal-inner-wrap {
      margin: 10vh auto;
    }
  
    .modal-footer {
      flex-direction: row;
  
      button {
        margin-bottom: 0;
        width: max-content;
      }
    }
  }
}
