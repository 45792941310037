.breadcrumbs {
  padding-bottom: 10px;
  .item {
    //hack
    &:after {
      font-size: 40px !important;
      font-weight: 900 !important;
      line-height: 26px !important;
      color: $c-green;
    }
  }
  .items {
    padding-top: 20px;
    border-top: 1px solid $c-light-grey;
    li {
      color: $c-blue;
      &.item {
        strong {
          @extend .text__thin;
          @extend .text__size14;
          color: $c-blue;
        }
        a {
          @extend .text__thin;
          @extend .text__size14;
          color: $c-blue;
          &:hover {
            color: $c-green;
          }
        }
        &.home {
          a {
            @extend .text__thin;
            @extend .text__size14;
            color: $c-blue;
            &:hover {
              color: $c-green;
            }
          }
        }
      }
    }
  }
}