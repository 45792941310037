.form {
 .fieldset {
   margin-bottom: 30px;
   padding: 0;
   .legend {
     @extend .text__thin;
     @extend .text__size20;
     color: $c-blue;
     margin: 0;
   }
   .field {
     &>.label {
       width: auto;
       display: inline-block;
       float: none;
       text-align: left;
       text-transform: uppercase;
       &::after {
         position: static !important;
       }
       span {
         @extend .text__thin;
         @extend .text__size14;
         color: rgba($c-blue, 0.5);
        }
      }
     &>.control {
       width: 100%;
       float: none;
       input, select {
         height: 50px;
         font-family: 'Montserrat', sans-serif;
         background: $c-grey-input;
         border: none;
         padding: 13px 20px 16px 17px;
         @extend .text__thin;
         @extend .text__size18;
         color: $c-blue;
       }
     }
   }
 }
}