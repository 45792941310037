@mixin blockProductList() {
  .block-title.title {
    color: $c-blue;
  }
  padding-top: 140px;
  .products-grid.grid {
    margin: 0 -10px;
    .product-items {
      margin-left: 0 !important;
      display: flex;
      flex-flow: row wrap;
    }
    .product-item {
      padding: 0 10px !important;
      margin: 0 !important;
      box-sizing: border-box;
      width: 100%;
      @media screen and (min-width: 450px) {
        width: 50%;
      }
      @media screen and (min-width: 768px) {
        width: 25%;
      }
      &-name {
        margin: 0;
      }
      &-details {

      }
      &-info {
        width: auto;
        position: relative;
        &::after, &::before {
          content: "";
          position: absolute;
          top: 0;
          height: 7px;
        }
        &::after {
          right: 75%;
          left: 0;
          background: $c-green;
        }
        &::before {
          right: 0;
          left: 25%;
          background: $c-light-blue;
        }
      }
      &-actions {
        display: none;
      }
      &-link {
        font-weight: 100;
        font-size: 26px;
        line-height: 34px;
        color: $c-blue;
        margin: 0;
        padding: 35px 0 7px 0;
        display: inline-block;
      }
      &-details {
        .price-box {
          margin: 0;
          .price {
            font-weight: 100;
            font-size: 26px;
            line-height: 34px;
            color: $c-light-blue;
            margin: 0;
            padding-bottom: 70px;
            display: inline-block;
          }
        }
      }
    }
  }
}


.block-products-list {
  position: relative;
  &__btn-wrapper {
    text-align: center;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: -15px;
    z-index: -1;
    @media screen and (min-width: 1280px) {
      left: calc((50vw - 620px) * -1);
    }
  }
  &.grid {
    @include blockProductList();
  }
}
