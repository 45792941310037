.page-header {
  border-bottom: none !important;
  .panel {
    &.header {
      padding: 21px 15px;
      .links {
        li {
          a {
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        a {
          font-size: 14px;
          font-weight: 100;
          color: $c-white;
          line-height: 17px;
          transition: .3s;
          &:hover {
            color: $c-green;
            text-decoration: none;
          }
        }
        .greet {
          span {
            color: $c-white;
          }
        }
      }
    }
    &.wrapper {
      background: $c-blue;
      margin-bottom: 21px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        width: calc(100% - 330px);
        height: 21px;
        background: $c-blue;
        transform: skewX(45deg);
        right: -15px;
      }
    }
  }
  &>.header {
    &.content {
      padding: 21px 0 0 0;
      &>.nav-toggle {
        &::before {
          font-size: 50px;
          color: $c-blue;
        }
      }
      &>.logo {
        width: 100px;
        margin: 10px auto 25px 80px;
        @include min-screen(365px) {
          width: 150px;
          margin: 3px auto 25px 80px;
        }
        @include min-screen(500px) {
          width: auto;
          margin: -8px auto 25px 80px;
        }
        @include min-screen(1300px) {
          margin: -8px auto 25px 0;
        }
      }
      .minicart-wrapper {
        margin: 7px 30px 0 10px;
        @include min-screen(400px) {
          margin: 7px 30px 0 30px;
        }
        @include min-screen(768px) {
          margin: 7px 30px 0 50px;
        }
        @include min-screen(992px) {
          margin: 7px 30px 0 80px;
        }
        .action.showcart {
          position: relative;
          &::before {
            content: "";
            width: 45px;
            height: 38px;
            background-image: url('../images/basket_dkm.svg');
          }
          .counter.qty {
            border-radius: 0;
            position: absolute;
            top: -18px;
            left: 20px;
            transform: rotate(45deg);
            border: 1px solid $c-white;
            .counter-number {
              display: inline-block;
              @extend .text__bold;;
              font-size: 15px;
              letter-spacing: 2px;
              transform: rotate(-45deg);
            }
          }
        }
        .block-minicart {
          border: none;
          background: $c-grey-input;
          &:after {
            content: unset;
          }
          &:before {
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $c-grey-input;
          }
          .minicart-items-wrapper {
            border-top: 1px solid $c-light-grey;
            border-bottom: 1px solid $c-light-grey;
            border-left: none;
            border-right: none;
            .minicart-items {
             .product-item {
               &-details {
                 padding-left: 175px;
                 .product.actions {
                   position: relative;
                   top: -5px;
                   a {
                     &::before {
                       color: $c-blue;
                       transition: .3s;
                     }
                     &:hover {
                       &::before {
                         color: $c-green;
                       }
                     }
                   }
                 }
               }
               &-name {
                 a {
                   @extend .text__bold;;
                   @extend .text__size20;
                   letter-spacing: 0;
                   color: $c-green;
                   transition: .3s;
                   text-transform: uppercase;
                   &:hover {
                     color: $c-blue;
                   }
                 }
               }
               &-pricing {
                .price {
                  @extend .text__thin;
                  @extend .text__size18;
                  color: $c-blue;
                }
                 .details-qty {
                   margin-top: 60px;
                   .label {
                     @extend .text__thin;
                     @extend .text__size14;
                     color: rgba($c-blue, 0.5)
                   }
                   .item-qty {
                     padding: 0;
                     background: transparent;
                     border: none;
                     @extend .text__size26;
                   }
                 }
               }
             }
            }
          }
          .actions {
            .viewcart {
              @extend .text__thin;
              @extend .text__size18;
              color: $c-blue;
              transition: .3s;
              &:hover {
                color: $c-green;
              }
            }
          }
          .items-total {
            display: block;
            float: none;
            .count, span {
              @extend .text__size18;
              color: $c-blue;
            }
            .count {
              @extend .text__bold;;
            }
            span {
              @extend .text__thin;
            }
          }
          .subtitle.empty {
            @extend .text__size18;
            @extend .text__thin;
            color: $c-blue;
          }
          .subtotal {
            margin: 10px 10px 10px;
            color: $c-blue;
            .label {
              @extend .text__size18;
              @extend .text__thin;
            }
            .price {
              @extend .text__bold;
              @extend .text__size20;
            }
          }
        }
      }
      .block-search {
        .minisearch {
          .field.search {
            .label {
              @include min-screen(640px) {
                margin-top: 10px;
              }
              @include min-screen(768px) {
                margin-top: 0;
              }
              &::before {
                color: $c-blue !important;
              }
            }
            .control {
              border-top: none;
              margin: 0 15px -1px;
              input {
                height: 50px;
                background: $c-grey-input;
                border: none;
                padding: 13px 20px 16px 17px;
                @extend .text__thin;
                @extend .text__size18;
                color: $c-blue;
                @include min-screen(767px) {
                  border-top: none;
                  border-right: none;
                  border-left: none;
                  background: transparent;
                  border-bottom: 1px solid $c-light-grey;
                  &::placeholder {
                    font-weight: 100;
                    color: $c-blue;
                    font-size: 18px;
                  }
                }
              }
              .nested {
                display: none;
              }
            }
          }
          .actions {
            .search {
              &::before {
                font-size: 34px;
                line-height: 38px;
                color: $c-blue;
                transition: .3s;
              }
              &:hover {
                &:before {
                  color: $c-green;
                }
              }
            }
          }
        }
      }
    }
  }
}