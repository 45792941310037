.cart.main.actions {
  .action {
    @include lib-button();
    background: $c-green;
    border-radius: 0 !important;
    border: 1px solid $c-green;
    color: $c-white;
    transition: .3s;
    &:hover {
      background: transparent;
      color: $c-green;
      border: 1px solid $c-green;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}