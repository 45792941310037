.categoriesPromo {
  padding-top: 50px;
  &__top-bar {
    padding: 35px 40px 45px 50px;
    @media screen and (min-width: 450px) {
      padding: 35px 40px 45px 70px;
    }
    background: $c-blue;
    margin-bottom: 5px;
    h3, h4 {
      margin: 0;
    }
  }
  &__main {
    background: $c-white;
  }
  &__link-wrapper {
    padding: 90px 50px 40px 50px;
    @media screen and (min-width: 450px) {
      padding: 90px 50px 40px 95px;
    }
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    margin-bottom: 5px;
    &:hover {
      text-decoration: none;
    }
  }
  &__header {
    text-transform: uppercase;
    padding-bottom: 40px;
    h5 {
      margin: 0;
    }
  }
  &__content {
    z-index: 2;
    position: relative;
    &::after, &::before {
      content: "";
      position: absolute;
      width: 65px;
      height: 7px;
      top: -90px;
    }
    &::after {
      left: 0;
      background: $c-green;
    }
    &::before {
      left: 65px;
      background: $c-light-blue;
    }
  }
  &__bg {
    position: absolute;
    background: $c-light-grey;
    transform: skewX(-45deg);
    z-index: 1;
    &--first {
      height: 100%;
      top: 0;
      left: -80%;
      width: 210%;
      @media screen and (min-width: 380px) {
        left: -60%;
        width: 170%;
      }
      @media screen and (min-width: 500px){
        width: 150%;
      }
      @media screen and (min-width: 768px){
        width: 175%;
      }
      @media screen and (min-width: 850px){
        width: 170%;
      }
      @media screen and (min-width: 900px){
        width: 160%;
      }
      @media screen and (min-width: 992px){
        width: 150%;
      }
    }
    &--second {
      width: 15%;
      height: 15%;
      right: -11%;
      bottom: -3%;
    }
  }
  &__lead {
    @media screen and (min-width: 450px) {
      padding-right: 60px;
    }
    @media screen and (min-width: 768px) {
      padding-right: 0;
    }
  }
  &__image {
    position: absolute;
    max-width: 50%;
    height: auto;
    bottom: 1px;
    right: 0;
  }
  &__btn-wrapper {
    padding: 65px 0 140px;
    text-align: center;
  }
  &__category {
    &-btn-wrapper {
      margin-left: -35px;
    }
  }
}