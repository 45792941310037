.tripleLead {
  position: relative;
  padding-top: 150px;
  @media screen and (min-width: 768px) {
    padding-top: 450px;
  }
  &__img {
    display: block;
    height: 100%;
    width: auto;
  }
  &__main {}
  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    z-index: -1;
    left: -15px;
    @media screen and (min-width: 768px) {
      left: -20px;
    }
    @media screen and (min-width: 1280px) {
      left: calc((50vw - 620px) * -1);
    }
    &::after, &::before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 60px;
      height: 60px;
      top: 0;
    }
    &::after {
      background: $c-green;
      left: 0;
    }
    &::before {
      background: $c-light-blue;
      left: 60px;
    }
  }
  &__top-bar {
    padding: 35px 40px 45px 40px;
    @media screen and (min-width: 380px) {
      padding: 35px 40px 45px 70px;
    }
    background: $c-blue;
    margin-right: -5px;
    h3, h4 {
      margin: 0;
    }
  }
  &__lead {
    text-align: center;
    padding: 115px 45px 50px 45px;
    background: $c-light-grey;
    &::after, &::before {
      content: "";
      position: absolute;
      width: 65px;
      height: 7px;
      top: 65px;
    }
    &::after {
      left: 50%;
      background: $c-green;
    }
    &::before {
      left: calc(50% - 65px);
      background: $c-blue;
    }
    &--grey {
      background: $c-light-grey;
    }
    &--white {
      background: $c-white;
    }
  }
  &__row {
    display: flex;
    justify-content: stretch;
    flex-flow: column;
    @media screen and (min-width: 768px) {
      flex-flow: row;
    }
  }
}