.page-footer {
  background: $c-blue;
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    content: "";
    display: inline-block;
    width: 33%;
    height: 21px;
    background: $c-white;
    transform: skewX(45deg);
    right: -15px;
    top: 0;
  }
  .footer.content {
    display: none;
  }
  .footer {
    &__logo {
      padding: 120px 0 130px 0;
    }
    &__group {
      ul {
        margin-bottom: 0 !important;
      }
      margin-bottom: 60px;
      h3, p, a, li {
        margin: 0;
      }
      position: relative;
      &::after, &::before {
        position: absolute;
        display: inline-block;
        width: 65px;
        height: 7px;
        content: "";
        top: 0;
      }
      &::after {
        background: $c-light-blue;
        left: 65px;
      }
      &::before {
        background: $c-green;
        left: 0;
      }
      &-header {
        padding: 35px 0;
        text-transform: uppercase;
      }
      &-little {
        padding-bottom: 30px;
      }
      &-link, &-paragraph {
        position: relative;
        i {
          position: absolute;
          top: 2px;
          left: -35px;
        }
      }
      &-link {
        transition: .3s;
        &:hover {
          color: $c-green;
          text-decoration: none;
        }
      }
    }
    &.links {
      @include list-unstyled;
      a {
        @extend .footer__group-link;
        @extend .text__light;
        @extend .text__size18;
        @extend .color--white;
      }
    }
    &__square {
      display: inline-block;
      width: 60px;
      height: 60px;
      &--blue {
        background: $c-light-blue;
      }
      &--green {
        background: $c-green;
      }
    }
    &__copyrights {
      padding-top: 30px;
      position: relative;
      left: -15px;
      @media screen and (min-width: 768px) {
        left: -20px;
      }
      @media screen and (min-width: 1280px) {
        left: calc((50vw - 620px) * -1);
      }
      width: 100vw;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-end;
      &-devhero {
        width: 160px;
        margin-right: 30px;
      }
    }
  }
}