.nav-sections {
  background: $c-white;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 71px;
    top: 0;
    left: 0;
    right: 0;
    background: $c-blue;
    @include min-screen(1300px) {
      content: unset;
    }
  }
  &-item {
    &-content {
      .header.links {
        a {
          text-transform: uppercase;
        }
      }
    }
    &-title {
      background: transparent;
      text-transform: uppercase;
      border: none;
      &.active {
        background: white;
        color: $c-green;
      }
    }
    &-switch {
      @extend .text__thin;
      @extend .text__size18;
      color: $c-green;
      transition: .3s;
      &:hover {
        color: $c-blue;
      }
    }
  }
  .section-items {
    .navigation {
      background: $c-white;
      @include min-screen(1300px) {
        ul {
          margin: 0 -18px;
          display: flex;
          justify-content: space-between;
          flex-flow: column wrap;
          @include min-screen(1300px) {
            flex-flow: row nowrap;
          }
          li {
            margin: 0;
            .submenu {
              animation-duration: .3s;
              animation-fill-mode: both;
              animation-name: fadeIn;
              background: $c-light-grey;
              padding: 30px 0 40px 0;
              display: none;
              flex-flow: column;
              li {
                text-align: left;
                a {
                  padding: 15px 5px;
                }
              }
            }
            a {
              padding: 0 18px;
              span {
                white-space: nowrap;
                transition: .3s;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 20px;
                letter-spacing: 3px;
                color: $c-blue;
              }
              &:hover {
                span {
                  color: $c-green;
                }
              }
            }
          }
        }
      }
      .submenu {
        li {
          a {
            @include min-screen(1300px) {
              span {
                font-weight: 200;
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 2px;
              }
            }
          }
        }
      }
    }
  }
}