.color {
  &--blue {
    color: $c-blue;
  }
  &--green {
    color: $c-green;
  }
  &--white {
    color: $c-white;
  }
}