body {
  font-family: 'Montserrat', sans-serif;
}

.cms-home {
  .page-title-wrapper {
    display: none;
  }
}

.page-layout-2columns-left {
  background: $c-white !important;
  .columns {
    .column {
      &.main {
        width: 75%;
      }
    }
    .sidebar {
      &-additional {
        width: 25%;
        display: none;
      }
      &-main {
        width: 25%;
      }
    }
  }
}


.page-wrapper {
  overflow: hidden;
}