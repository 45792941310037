.page-layout-page-contact {
  .page-title-wrapper {
    .page-title {
      span {
        @extend .text__bold;
        @extend .text__size26;
        color: $c-green;
        text-transform: uppercase;
      }
    }
  }
  .contactPage {
    margin-bottom: 105px;
    &__info {
      padding: 85px 0 10px 35px;
    }
    &__map {
      position: relative;
      left: -20px;
      width: 100vw;
      margin-top: 60px;
      @media screen and (min-width: 992px) {
        margin-top: 0;
        width: calc(100% + 20px);
        left: 0;
      }
      @media screen and (min-width: 1240px) {
        width: calc(100% + (50vw - 615px));
      }
    }
    &__paragraph, &__link {
      margin: 0;
      @extend .text__thin;
      @extend .text__size18;
      color: $c-blue;
      position: relative;
      padding-bottom: 5px;
      i {
        position: absolute;
        bottom: 9px;
        left: -30px;
      }
    }
    &__link {
      display: block;
      transition: .3s;
      &:hover {
        color: $c-green;
        text-decoration: none;
      }
    }
    &__little-group {
      padding-bottom: 30px;
    }
  }
}
