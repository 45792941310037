.message {
    @extend .text__thin;
    @extend .text__size16;
    margin: 0 0 30px 0 !important;
    & > *:first-child:before {
        top: 46% !important;
        right: 20px !important;
    }
}

.message.info {
    @include lib-message-icon-inner(info);
    padding: 25px 40px 27px 40px !important;
}

.message.error {
    @include lib-message-icon-inner(error);
    padding: 25px 40px 27px 40px !important;
}

.message.warning {
    @include lib-message-icon-inner(warning);
    padding: 25px 40px 27px 40px !important;
}

.message.notice {
    @include lib-message-icon-inner(notice);
    padding: 25px 40px 27px 40px !important;
}

.message.success {
    @include lib-message-icon-inner(success);
    padding: 25px 40px 27px 40px !important;
    border: 1px solid $c-green;
}
