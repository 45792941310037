.page-with-filter,.catalogsearch-result-index {
  .page-title {
    @extend .text__size26;
    @extend .text__bold;
    color: $c-green;
    text-transform: uppercase;
    margin-bottom: 60px;
  }
  .category-view {
    display: none;
  }
  .toolbar {
    &.toolbar {
      &-products {
        margin-left: 0;
        padding-left: 0;
        .modes {
          &-mode {
            border: none;
            padding: 0;
            transition: .3s;
            &:hover {
              background: unset;
              &::before {
                color: $c-green;
              }
            }
            &::before {
              transition: .3s;
              font-size: 40px;
              color: $c-blue;
            }
            &.active {
              &::before {
                color: $c-green;
              }
            }
          }
        }
      }
      .limiter {
        &--text {
          @extend .text__thin;
          @extend .text__size14;
          color: $c-blue;
        }
        .label {
          span {
            @extend .text__thin;
            @extend .text__size14;
            color: $c-blue;
          }
        }
        .control {
          #limiter {
            display: none;
          }
          .select2-container {
            display: inline-block;
            padding: 3px 4px;
            margin: 0 3px;
            @extend .text__thin;
            @extend .text__size14;
            color: $c-green;
            border-bottom: 1px solid $c-green;
          }
        }
      }
    }
    &-amount {
      @extend .text__thin;
      @extend .text__size14;
      color: $c-blue;
    }
    &-sorter {
      .sorter {
        &-label {
          @extend .text__thin;
          @extend .text__size14;
          color: $c-blue;
        }
        &-action {
          &::before {
            color: $c-green;
          }
        }
      }
      .select2-container {
        display: inline-block;
        padding: 3px 4px;
        margin: 0 3px;
        @extend .text__thin;
        @extend .text__size14;
        color: $c-green;
        border-bottom: 1px solid $c-green;
      }
    }
    #sorter {
      display: none;
    }
  }
  .products.wrapper {
    &.grid {
      .product-items {
        margin-left: 0 !important;
        display: flex;
        flex-flow: row wrap;
      }
      .product-item {
        padding: 0 10px !important;
        margin: 0 !important;
        box-sizing: border-box;
        width: 100%;
        @media screen and (min-width: 550px) {
          width: 50%;
        }
        @media screen and (min-width: 992px) {
          width: 33%;
        }
        &-name {
          margin: 0;
        }
        &-details {

        }
        &-info {
          margin: 0 auto;
          position: relative;
          &::after, &::before {
            content: "";
            position: absolute;
            top: 0;
            height: 7px;
            z-index: 2;
          }
          &::after {
            right: 75%;
            left: 0;
            background: $c-green;
          }
          &::before {
            right: 0;
            left: 25%;
            background: $c-light-blue;
          }
        }
        &-actions {
          display: none;
        }
        &-link {
          font-weight: 100;
          font-size: 26px;
          line-height: 34px;
          color: $c-blue;
          margin: 0;
          padding: 35px 0 7px 0;
          display: inline-block;
        }
        &-details {
          .price-box {
            margin: 0;
            .price {
              font-weight: 100;
              font-size: 26px;
              line-height: 34px;
              color: $c-light-blue;
              margin: 0;
              padding-bottom: 70px;
              display: inline-block;
            }
          }
        }
      }
    }
    &.list {
      .product-item {
        margin: 0 0 30px 0 !important;
        &-photo {
          display: block;
          width: 100%;
          text-align: center;
          @media screen and (min-width: 600px) {
            display: table-cell;
            width: 30%;
            text-align: left;
          }
          @media screen and (min-width: 768px) {
            max-width: 220px;
          }
          @media screen and (min-width: 992px) {
            max-width: unset;
          }
          img {
            position: relative;
            &::after, &::before {
              content: "";
              position: absolute;
              top: 0;
              height: 7px;
              z-index: 2;
              width: 65px;
            }
            &::after {
              left: calc(50% - 65px);
              background: $c-green;
            }
            &::before {
              left: 50%;
              background: $c-light-blue;
            }
          }
        }
        &-description {
          margin: 0;
          @extend .text__light;
          @extend .text__size18;
          color: $c-blue;
          p {
            margin: 0;
            padding-bottom: 20px;
          }
          .action.more {
            @extend .btn;
            @extend .btn-primary;
            margin-left: -35px;
          }
        }
        &-actions {
          display: none;
        }
        &-name {
          margin: 0;
          padding-bottom: 7px;
        }
        &-link {
          @extend .text__light;
          @extend .text__size26;
          color: $c-blue;
        }
        &-details {
          display: block;
          @media screen and (min-width: 600px) {
            display: table-cell;
          }
          .price-box {
            margin: 0;
            padding-bottom: 25px;
            .price {
              @extend .text__light;
              @extend .text__size26;
              color: $c-light-blue;
            }
          }
        }
      }
    }
  }
  .sidebar {
    &-main {
      .block {
        &-title {
          display: none;
        }
        &-content {
          .filter {
            &-subtitle {
              display: none;
            }
            &-options {
              &-title {
                cursor: pointer;
                margin: 0;
                @extend .text__thin;
                @extend .text__size18;
                color: $c-blue;
                padding: 22.5px 0 18.5px 0;
                border-top: 1px solid $c-light-grey;
                border-bottom: 1px solid $c-light-grey;
              }
              &-content {
                padding: 0;
                transition: .3s;
                overflow: hidden;
                margin: 0;
                .items {
                  .item {
                    margin: 0;
                    a {
                      padding: 12px 0;
                      display: inline-block;
                      text-transform: capitalize;
                      @extend .text__thin;
                      @extend .text__size14;
                      color: $c-blue;
                      transition: .3s;
                      &:hover {
                        color: $c-green;
                      }
                    }
                    .count {
                      display: none;
                    }
                  }
                }
              }
              &-arrows {
                padding-right: 20px;
                i {
                  color: $c-green;
                }
              }
            }
          }
        }
      }
    }
  }
  .pages {
    &-items {
      .item {
        strong {
          color: $c-blue;
          width: 45px;
          height: 45px;
          display: inline-block;
          padding: 5px;
          background: $c-light-grey;
          box-sizing: border-box;
        }
        a {
          color: $c-blue;
          width: 45px;
          height: 45px;
          display: inline-block;
          padding: 5px;
          transition: .3s;
          &:hover {
            color: $c-green;
          }
        }
      }
    }
    &-item {
      &-next, &-previous {
        .action {
          width: 45px;
          height: 45px;
          border: 1px solid #29235C;
          padding: 5px;
          box-sizing: border-box;
          &::before {
            color: $c-blue;
          }
        }
      }
    }
  }
}
