.checkout-index-index {
  .opc {
    &-wrapper {
      @media screen and (min-width: 768px) {
        width: 50%;
      }

      .step-title {
        @extend .text__size26;
        @extend .text__thin;
        color: $c-blue;
      }

      .form-login {
        margin-top: 0;
      }

      .checkout-billing-address>fieldset {
        margin-bottom: 0;
      }

      #checkout-step-shipping {
        margin-top: 40px;
      }
    }

    &-sidebar {
      @media screen and (min-width: 768px) {
        width: 45%;
      }
    }

    &-block {
      &-summary {
        &>.title {
          @extend .text__size26;
          @extend .text__thin;
          color: $c-blue;
          border: none;
        }

        .items-in-cart {
          .title {
            @extend .text__size18;
            padding: 22px 0 17px 0;
            color: $c-blue;
            border-top: 1px solid $c-light-grey;
            border-bottom: 1px solid $c-light-grey;

            &:after {
              font-weight: 700;
              font-size: 75px;
              color: $c-green;
              line-height: 40px;
            }
          }
        }

        .product-item {
          margin: 20px 0 !important;

          &-details {
            padding-left: 175px;

            .price-excluding-tax {
              margin: 60px 0 0;
            }
          }

          &-inner {
            .subtotal {
              text-align: left;

              .price {
                @extend .text__thin;
                @extend .text__size18;
                color: $c-blue;
              }
            }
          }

          &-name {
            @extend .text__bold;
            @extend .text__size20;
            letter-spacing: 0;
            color: $c-green;
            text-transform: uppercase;

            &-block {
              display: block;

              .details-qty {
                .label {
                  text-transform: uppercase;
                  @extend .text__size14;
                  @extend .text__thin;
                  letter-spacing: 0;
                  color: rgba($c-blue, 0.5);
                }

                .value {
                  @extend .text__thin;
                  @extend .text__size20;
                  margin-left: 10px;
                }
              }
            }
          }

          .subtotal {
            display: block;
          }
        }
      }
    }

    &-estimated {
      &-wrapper {
        .minicart-wrapper {
          button.action {
            border-radius: 0;
            border: none;
            background: $c-green;

            &::before {
              color: $c-blue;
            }

            .counter {
              background: transparent;
            }
          }
        }
      }
    }
  }

  .checkout {
    &-container {
      padding-top: 60px;

      .authentication-wrapper {
        display: none;
      }
    }

    &-shipping-method {
      .table-checkout-shipping-method {
        tr {
          @extend .text__size18;
          @extend .text__thin;
          color: $c-blue;
        }
      }
    }
  }

  .form.form-discount {
    button.action {
      margin-bottom: 15px;
      background: $c-green;
      border-radius: 0;
      display: inline-block;
      font-weight: 600;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 2px solid $c-green;
      padding: 25px 40px 27px 40px !important;
      font-size: 20px !important;
      letter-spacing: 2.4px;
      line-height: 34px !important;
      text-transform: uppercase;
      transition: .3s;
      color: $c-white;

      &:hover {
        background: $c-white;
        color: $c-green;
      }
    }
  }

  .form .fieldset .field {

    &[name="shippingAddress.street.0"],
    &.field[name="billingAddressshared.street.0"] {
      &>label {
        display: none;
      }
    }
  }

  .action-hide-popup {
    padding: 15px;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 34px;
  }

  .action.primary.action-save-address {
    padding: 15px !important;
  }
}