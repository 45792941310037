.banner {
  position: relative;
  padding: 20px 0 50px 0;
  &__header {
    color: $c-blue;
    text-transform: uppercase;
    padding-bottom: 50px;
    position: relative;
    &::after, &::before  {
      position: absolute;
      content: "";
      width: 8px;
      left: 0;
      height: calc(50% - 35px);
    }
    &::after {
      background: $c-light-blue;
      top: 10px;
    }
    &::before {
      background: $c-green;
      bottom: 60px;
    }
  }
  &__subheader {
    padding-bottom: 70px;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: -20px;
    z-index: -1;
    @media screen and (min-width: 1280px) {
      left: calc((50vw - 620px) * -1);
    }
  }
  &__btn-wrapper {
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }
}