.customer-account-login {
  .page-title-wrapper {
    display: none;
  }
  .login-container {
    padding-bottom: 50px;
    display: flex;
    flex-flow: column;
    @media screen and (min-width: 992px) {
      flex-flow: row;
    }
  }
  .block {
    &-title {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      border-bottom: none !important;
      &__top {
        padding-bottom: 23px;
        position: relative;
        &::after,&::before {
          content: "";
          position: absolute;
          display: inline-block;
          height: 7px;
          width: 65px;
          bottom: 0;
        }
        &::after {
          background: $c-green;
          left: 0;
        }
        &::before {
          background: $c-light-blue;
          left: 65px;
        }
      }
      &__bottom {
        padding: 15px 0 30px 0;
      }
    }
    &-customer-login {
      box-sizing: border-box;
      background: $c-light-grey;
      margin-bottom: 0;
      width: 100%;
      padding: 55px 15px 70px 15px;
      @media screen and (min-width: 500px) {
        padding: 55px 40px 70px 60px;
      }
      @media screen and (min-width: 992px) {
        width: 50%;
        padding: 70px 35px 150px 40px;
      }
      @media screen and (min-width: 1070px) {
        padding: 70px 55px 150px 60px;
      }
      @media screen and (min-width: 1200px) {
        padding: 70px 75px 150px 81.5px;
      }
      .block-content {
        .actions-toolbar {
          text-align: right;
          padding-top: 15px;
          .primary {
            @media screen and (min-width: 767px) {
              float: none;
            }
          }
          .secondary {
            display: block;
            width: 100%;
            text-align: right;
            a {
              @extend .text__size16;
              @extend .text__thin;
              color: $c-blue;
              transition: .3s;
              &:hover {
                color: $c-green;
              }
            }
          }
        }
        .fieldset {
          &:after {
            text-align: left;
            @extend .text__size14;
            @extend .text__thin;
          }
        }
      }
    }
    &-new-customer {
      box-sizing: border-box;
      margin: 0 0 !important;
      background: $c-blue;
      width: 100%;
      @media screen and (min-width: 992px) {
        width: 50%;
        margin: 40px 0 !important;
      }
      padding: 55px 15px 110px 15px;
      @media screen and (min-width: 500px) {
        padding: 55px 40px 110px 60px;
      }
      @media screen and (min-width: 1070px) {
        padding: 55px 60px 50px 80px;
      }
      @media screen and (min-width: 1200px) {
        padding: 55px 85px 50px 120px;
      }
      .block-content {
        p {
          color: $c-white;
          @extend .text__size18;
          @extend .text__thin;
          margin-bottom: 35px;
        }
        .actions-toolbar {
          a.primary {
            border-radius: 0;
            background: transparent;
            color: $c-green;
            display: block;
            width: unset;
            @media screen and (min-width: 768px) {
              width: auto;
              display: inline-block;
            }
            &:hover {
              color: $c-white;
              background: $c-green;
            }
          }
        }
      }
    }
  }
}