.select2 {
  &-container {
    vertical-align: bottom;
    position: relative;
    min-height: 33px;
    margin-top: 30px;
    z-index: 100;
    background: rgba($c-white, 0.8);
    cursor: pointer;
  }
  &-search {
    display: none;
  }
  &-selection {
    &__rendered {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
    }
  }
  &-results {
    &__options {
      @include list-unstyled;
    }
    &__option {
      color: $c-green;
      padding: 5px 15px;
      text-align: center;
      cursor: pointer;
    }
  }
}