.slogan {
  padding: 150px 0 80px 0;
  position: relative;
  &__bg {
    position: absolute;
    top: 0;
    right: -15px;
    z-index: -1;
    @media screen and (min-width: 768px) {
      right: -20px;
    }
    @media screen and (min-width: 1280px) {
      right: calc((50vw - 620px) * -1);
    }
    img {
      max-height: 100%;
      width: auto;
    }
  }
  &__subHeader,&__header-top, &__header-bottom {
    h5 {
      margin: 0;
    }
  }
  &__main {
    padding: 90px 0 45px 60px;
    @media screen and (min-width: 480px) {
      padding: 90px 0 45px 90px;
    }
    @media screen and (min-width: 768px) {
      padding: 170px 0 90px 170px;
    }
    position: relative;
  }
  &__borders {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 67%;
    border-top: 1px solid $c-blue;
    border-left: 1px solid $c-blue;
    &::after, &::before {
      position: absolute;
      content: "";
      height: 7px;
      bottom: 0;
    }
    &::after {
      left: -1px;
      right: 75%;
      background: $c-green;
    }
    &::before {
      right: 0;
      left: 25%;
      background: $c-light-blue;
    }
  }
  &__subHeader {
    padding-bottom: 70px;
    text-transform: uppercase;
  }
  &__header {
    &-bottom {
      padding-bottom: 15px;
    }
  }
  &__btn-wrapper {
    margin-left: -35px;
  }
}
