.catalog-product-view {
  .product {
    &-social-links {
      display: none;
    }
    &-info {
      &-main {
        .price-tier_price {
          display: none;
        }
        .page-title {
          span {
            @extend .text__thin;
            @extend .text__size26;
            color: $c-blue;
          }
        }
        .product.attribute.overview {
          @extend .text__light;
          @extend .text__size18;
          color: $c-blue;
        }
      }
      &-price {
        border-top: 1px solid $c-light-grey;
        border-bottom: 1px solid $c-light-grey;
        padding: 10px 0 9px 0;
        @extend .clearfix;
        .price-box {
          padding: 10px 0;
          float: left;
          margin: 0;
          display: inline-block;
          .price {
            @extend .text__thin;
            @extend .text__size26;
            color: $c-light-blue;
          }
        }
      }
      &-stock-sku {
        @media screen and (min-width: 500px) {
          display: inline-block;
          float: right;
        }
        .stock, .product {
          display: block;
          margin: 0;
          @media screen and (min-width: 500px) {
            text-align: right;
          }
          span, div, strong {
            @extend .text__thin;
            @extend .text__size14;
            text-transform: uppercase;
          }
        }
        .stock {
          color: $c-green;
        }
        .product {
         color: $c-blue;
        }
      }
    }
    &-reviews {
      &-summary {
        display: none;
      }
    }
    &-add-form {
      .box-tocart {
        .fieldset {
          .field {
            vertical-align: bottom;
            padding-bottom: 0;
            &.qty {
              @extend .clearfix;
              padding-right: 30px;
              display: block;
              @media screen and (min-width: 1190px) {
                display: table-cell;
              }
            }
            .label,.control {
              display: inline-block;
              float: left;
            }
            .label {
              padding: 27px 30px 0 0;
              span {
                @extend .text__thin;
                @extend .text__size16;
                text-transform: uppercase;
              }
            }
            .control {
              input {
                width: 90px;
                height: 90px;
                border: 2px solid $c-blue;
              }
            }
          }
        }
      }
    }
    &.info.detailed {
      #tab-label-reviews-title {
        display: none;
      }
      .data {
        &.items {
          background: $c-blue;
          &>.item {
            &.title {
              margin: 0 0 0 0;
              &>.switch {
                transition: .3s;
                padding: 21px 65px 25px 65px !important;
                border: none !important;
                color: $c-white;
                background: $c-blue;
                @extend .text__bold;;
                @extend .text__size20;
                &:hover {
                  color: $c-blue;
                  background: $c-light-grey;
                }
              }
              &.active {
                &>.switch {
                  color: $c-blue;
                  background: $c-light-grey;
                }
              }
            }
            &.content {
              background: $c-light-grey;
              margin-top: 7px;
              padding: 100px 30px;
              @media screen and (min-width: 768px) {
                margin-top: 66px;
              }
              &>div {
                margin: 0 auto;
                max-width: 695px;
                &.description {
                  .value {
                    @extend .text__thin;
                    @extend .text__size18;
                    color: $c-blue;
                  }
                }
                &.table-wrapper {
                  table {
                    tbody {
                      tr {
                        &>th {
                          padding: 20px 30px 10px 30px;
                        }
                        &>td {
                          padding: 20px 30px 10px 0;
                        }
                        background: $c-grey;
                        @extend .text__thin;
                        @extend .text__size18;
                        color: $c-blue;
                        &:nth-child(even) {
                          background: $c-grey-silver;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}