a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}
button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    background: $c-green;
    border-radius: 0 !important;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 2px solid $c-green;
    padding: 25px 40px 27px 40px !important;
    font-size: 20px !important;
    letter-spacing: 2.4px;
    line-height: 34px !important;
    text-transform: uppercase;
    transition: .3s;
    color: $c-white;
    &:hover {
        background: $c-white;
        color: $c-green;
    }
}
